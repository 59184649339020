import './food.js';
import './footer.js';
import './header.js';
import './hero.js';

import Highlights from '../svelte-components/highlights/Highlights.html';

new Highlights({
	target: document.querySelector('#highlights')
});
