<div id="highlights-container">
	{#each Object.keys(highlights) as name}
		{#if isActive(active, name)}
			<svelte:component this={highlights[name]} />
		{/if}
	{/each}
</div>

<style>
	#highlights-container {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-around;
	}
</style>

<script>
	export default {
		data() {
			return {
				highlights: {

				},
				active: {

				}
			};
		},
		helpers: {
			isActive(active, highlight) {

				if (active[highlight]) {
					const now = new Date();

					const { start, end } = active[highlight];
					if (start && start > now)
						return false;
					if (end && end < now)
						return false;
				}

				return true;
			}
		}
	};
</script>
